import ApiService from "./ApiService"
import { API_BASE_URL } from "redux/constants";

let localStorageObject = localStorage.getItem("User_Full_Info");
let userFulInfo = JSON.parse(localStorageObject);


export async function apiDeleteMerchant(id) {
  
  const response = await fetch(API_BASE_URL + `Merchant/DeleteMerchant?Id=${id}`,
    {
      method: "POST",
      headers: {
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    })
  const data = await response.json();
  
  return data;
}

export async function UpdateProductCategoryStatus(Id, Status) {
  
  const response = await fetch(API_BASE_URL + `Merchant/UpdateProductCategoryStatus?Id=${Id}&Status=${Status}`, {
      method: "POST",
      headers: {
          "Content-type": "application/json; charset=UTF-8",
        
      }
  });
  const data = await response.json();
  
  return data;
}
export async function GetRequestedProductCategories() {
  
  const response = await fetch(API_BASE_URL +'Merchant/GetRequestedProductCategories',{
    method: "GET",
 
  });
  const data = await response.json();
   
  return data;
}
export async function apiGetAllDefaultSettings() {
   
  const response = await fetch(API_BASE_URL +'Merchant/GetAllDefaultSettings',{
    method: "GET",
 
  });
  const data = await response.json();
   
  return data;
}

export async function AddSettings(object,isUpdate) {
  
     const response = await fetch(API_BASE_URL +'Merchant/AddDefaultSetting', {
       method: "POST",
       body: JSON.stringify({
        settings:object,
        isUpdate:isUpdate

       }),
       headers: {
         "Content-type": "application/json; charset=UTF-8",
        //  "key": userFulInfo.id,
        //  "orgId": userFulInfo.organizationId
       }
     });
     var data = await response.json();
     
     return data;
   

    }




export async function AccountSignIn (userName,password) {
  ;
  const response = await fetch(API_BASE_URL +'Account/Login', {
      method: "POST",
      body: JSON.stringify({
        email: userName,
        password: password
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
  });
  var data = await response.json();
  
  return data;
}
export async function apiDeleteProduct(id) {
  
  const response = await fetch(API_BASE_URL + `Merchant/DeleteProduct?Id=${id}`,
    {
      method: "POST",
      headers: {
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    })
  const data = await response.json();
  
  return data;
}

export async function UpdateProduct(object) {
  
  let formData = new FormData();
  formData.append('Id', object.id);
  formData.append('Name', object.name);
    formData.append('SKU', object.skU);
    formData.append('Description', object.description);
    formData.append('MerchantId', object.merchantId);
    formData.append('CategoryId', object.categoryId);

  const response = await fetch(API_BASE_URL + 'Merchant/UpdateProduct',
    {
      body: formData,
      method: "post",
      headers: {
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    });
  const data = await response.json();
  
  return data;
}
export async function AddProduct(object) {
  

  
    let formData = new FormData();
    formData.append('Name', object.name);
    formData.append('SKU', object.skU);
    formData.append('Description', object.description);
    formData.append('MerchantId', object.merchantId);
    formData.append('CategoryId', object.CategoryId);
    formData.append('TaxGroupId', object.taxId);
    formData.append('ExpirationDate', object.expirationDate);
    formData.append('RestockingLevel', object.restockingLevel);


    
    const response = await fetch(API_BASE_URL +'Merchant/AddProduct',
      {
        body: formData,
        method: "post",
        headers: {
          // "key": userFulInfo.id,
          // "orgId": userFulInfo.organizationId
        }
      });
    const data = await response.json();
    
    return data;
  }


  export async function apiGetAllMerchantById(id) {
   
    const response = await fetch(API_BASE_URL +`Merchant/GetMerchantById?id=${id}`,
    {
      method: "GET",
   
    });
    const data = await response.json();
     
    return data;
  }
export async function apiGetAllMerchants() {
   
  const response = await fetch(API_BASE_URL +'Merchant/GetMerchantsByAdmin',{
    method: "GET",
 
  });
  const data = await response.json();
   
  return data;
}

export async function GetMerchantsWithNoBusiness() {
   
  const response = await fetch(API_BASE_URL +'Merchant/GetMerchantsWithNoBusiness',{
    method: "GET",
 
  });
  const data = await response.json();
  
  return data;
}
export async function apiGetAllProducts() {
   
  const response = await fetch(API_BASE_URL +'Merchant/GetAllProducts',{
    method: "GET",
    headers: {
      // "key": userFulInfo.id,
      // "orgId": userFulInfo.organizationId
    }
  });
  const data = await response.json();
   
  return data;
}


export async function updateRole(object) {
  
  const response = await fetch(API_BASE_URL + 'SysConfig/UpdateRole', {
    method: "POST",
    body: JSON.stringify(object),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  })

  const data = await response.json();
  return data;
}

export async function apiDeleteRoles(id) {
  
  const response = await fetch(API_BASE_URL + `SysConfig/DeleteRole?Id=${id}`,
    {
      method: "POST"
    })
  const data = await response.json();
  
  return data;
}
export async function apiDeleteUsers(id) {
  
  const response = await fetch(API_BASE_URL + `SysConfig/DeleteUser?Id=${id}`,
    {
      method: "POST",
      headers: {
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    })
  const data = await response.json();
  
  return data;
}

export async function UpdateUser(object,id) {
  debugger
  let formData = new FormData();
  formData.append('Id',id);
  formData.append('FirstName', object.firstName);
  formData.append('LastName', object.lastName);
  formData.append('Gender', object.gender);
  formData.append('Image', object.image);
  formData.append('Email', object.email);
  formData.append('PhoneNumber', object.phoneNumber);
  formData.append('RoleId', object.roleId);
  formData.append('Password', object.password);

  const response = await fetch(API_BASE_URL + 'SysConfig/UpdateUser',
    {
      body: formData,
      method: "post"
    });
  const data = await response.json();
  
  return data;
}


export async function AddUser(object) {
  debugger

  
    let formData = new FormData();
    formData.append('FirstName', object.firstName);
    formData.append('LastName', object.lastName);
    formData.append('Gender', object.gender);
    formData.append('Image', object.image);
    formData.append('Email', object.email);
    formData.append('PhoneNumber', object.phoneNumber);
    formData.append('RoleId', object.roleId);
    formData.append('Password', object.password);
  //   formData.append('PlayersId', playerIds);

  
    const response = await fetch(API_BASE_URL +'SysConfig/AddUser',
      {
        body: formData,
        method: "post",
        headers: {
          // "key": userFulInfo.id,
          // "orgId": userFulInfo.organizationId
        }
      });
    const data = await response.json();
    
    return data;
  }

  
  export async function GetAllTaxGroupByMerchantId(id) {

    const response = await fetch(API_BASE_URL + `Merchant/GetAllTaxGroupByMerchantId?MerchantId=${id}`,{
      method: "GET",
      headers: {
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    });
    const data = await response.json();
    
    return data;
  }
  export async function GetProductById(id) {

    const response = await fetch(API_BASE_URL + `Merchant/GetProductById?Id=${id}`,{
      method: "GET",
      headers: {
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    });
    const data = await response.json();
    
    return data;
  }
  export async function GetRoleById(id) {

    const response = await fetch(API_BASE_URL + `SysConfig/GetRoleById?Id=${id}`,{
      method: "GET",
      headers: {
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    });
    const data = await response.json();
    
    return data;
  }
export async function GetUsersById(id) {

  const response = await fetch(API_BASE_URL + `SysConfig/GetUserById?Id=${id}`,{
    method: "GET",
    headers: {
      // "key": userFulInfo.id,
      // "orgId": userFulInfo.organizationId
    }
  });
  const data = await response.json();
  
  return data;
}

export async function UpdateCatogory(object) {
  
  const response = await fetch(API_BASE_URL + 'Merchant/UpdateProductCategory', {
    method: "POST",
    body: JSON.stringify({

      id: object.id,
      title: object.name,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      // "key": userFulInfo.id,
      // "orgId": userFulInfo.organizationId
    }
  })

  var data = await response.json();
  
  return data;
} 


export async function GetCatogoryById(id) {

  const response = await fetch(API_BASE_URL + `Merchant/GetProductCategoryById?Id=${id}`,{
    method: "GET",
    headers: {
      // "key": userFulInfo.id,
      // "orgId": userFulInfo.organizationId
    }
  });
  const data = await response.json();
  
  return data;
}


export async function apiDeleteCatogory(id) {
  
  const response = await fetch(API_BASE_URL + `Merchant/DeleteProductCategory?Id=${id}`,
    {
      method: "POST",
      headers: {
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    })
  const data = await response.json();
  
  return data;
}


export async function apiGetAllProductCatogory() {
   
  const response = await fetch(API_BASE_URL +'Merchant/GetAllProductCategories',{
    method: "GET",
    headers: {
      // "key": userFulInfo.id,
      // "orgId": userFulInfo.organizationId
    }
  });
  const data = await response.json();
   
  return data;
}

export async function apiGetAllUsers() {
   
  const response = await fetch(API_BASE_URL +'SysConfig/GetAllUsers',{
    method: "GET",
    headers: {
      // "key": userFulInfo.id,
      // "orgId": userFulInfo.organizationId
    }
  });
  const data = await response.json();
   
  return data;
}


export async function apiGetAllRoles() {
   
  const response = await fetch(API_BASE_URL +'SysConfig/GetAllRoles',{
    method: "GET",
    headers: {
      // "key": userFulInfo.id,
      // "orgId": userFulInfo.organizationId
    }
  });
  const data = await response.json();
   
  return data;
}

export async function addCatogory(object) {
  
     const response = await fetch(API_BASE_URL +'Merchant/AddProductCategory', {
       method: "POST",
       body: JSON.stringify({
         title: object.name,
       
       }),
       headers: {
         "Content-type": "application/json; charset=UTF-8",
        //  "key": userFulInfo.id,
        //  "orgId": userFulInfo.organizationId
       }
     });
     var data = await response.json();
     
     return data;
   }
export async function addRole(object) {
 
    const response = await fetch(API_BASE_URL +'SysConfig/AddRoles', {
      method: "POST",
      body: JSON.stringify({
        isAdmin:object.isAdmin,
        name: object.name,
        isSystemRole:object.isSystemRole
      
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        // "key": userFulInfo.id,
        // "orgId": userFulInfo.organizationId
      }
    });
    var data = await response.json();
    
    return data;
  }
  



  
    

    export async function GetPlayerById(id) {

        const response = await fetch(API_BASE_URL + `Players/GetPlayerById?Id=${id}`,{
          method: "GET",
          headers: {
            "key": userFulInfo.id,
            "orgId": userFulInfo.organizationId
          }
        });
        const data = await response.json();
        return data;
      }


      export async function apiDeletePlayer(id) {
         
        const response = await fetch(API_BASE_URL + `Players/DeletePlayer?Id=${id}`,
          {
            method: "POST",
            headers: {
              "key": userFulInfo.id,
              "orgId": userFulInfo.organizationId
            }
          })
        const data = await response.json();
        return data;
      }